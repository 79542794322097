@use '../abstract/colors' as *;
* {
    padding: 0;
    margin: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }

  html {
    scroll-behavior: smooth;
  }
  
  *::before,
  *::after {
    box-sizing: inherit;
  }

  .body {
    line-height: 1.3;
  }
  
  div,
  ul,
  ol,
  li,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  pre,
  form,
  fieldset,
  input,
  textarea,
  p,
  blockquote,
  th,
  td {
    margin: 0;
    padding: 0;
  }
  
  ol,
  ul {
    list-style: none;
  }
  
  a {
    text-decoration: none;
  }

  address{
    font-style:normal;
    font-size:100%;
  }
  
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 100%;
    font-weight: normal;
  }