@use '../abstract/function' as *;
@use '../abstract/colors' as *;
@use '../abstract/breakpoints' as *;

.header{
    position:fixed;
    padding:rem(32) 0;
    top:0px;
    left:0px;
    right:0px;
    z-index:12;
    width:100%;
    transition:all 0.25s ease-in-out;
    padding-block:rem(14);

    @include breakpoint-md{
        padding-block:rem(20);
    }
}

.header__wrapper--main{
    display:none;

    @include breakpoint-lg{
        display:flex ;
    }
}

.header-logo{
    margin-top:rem(10);
}

// ============= mobile header =============
.header__wrapper--mobile{
    @include breakpoint-lg{
        display:none;
    }
}

.header__logo-wrapper{
    img{ 
        width:rem(140);
        height:auto;
        margin-top:rem(10);

        @include breakpoint-md{
            width:rem(160);
        }
    }
}

// ========= sticky header ===========
.header--sticky {
    transition: 100ms ease 0s;
    background-color:$clr-orange-500;
    box-shadow: 0 2px 4px rgba(0,0,0,.2);
    animation: moveDown 0.45s ease-in;
}

@keyframes moveDown {
    from {
      transform: translateY(-100%);
      opacity:0;
    }
    to {
      transform: translateY(0);
      opacity:1;
    }
}