// ======== primary fonts =============
@font-face{
    font-family: 'Product Sans Regular';
    src: url("../asset/font/Product Sans Regular.eot");
    src: url("../asset/font/Product Sans Regular.eot?#iefix") format('embedded-opentype'),
         url("../asset/font/Product Sans Regular.otf") format('otf'),
         url("../asset/font/Product Sans Regular.svg#Product%20Sans%20Regular") format('svg'),
         url("../asset/font/Product Sans Regular.ttf") format('truetype'),
         url("../asset/font/Product Sans Regular.woff") format('woff'),
         url("../asset/font/Product Sans Regular.woff2") format('woff2');
}

// ========= other font ============= 
@font-face{
    font-family: 'Product Sans Bold';
    src: url("../asset/font/Product Sans Bold.eot");
    src: url("../asset/font/Product Sans Bold.eot?#iefix") format('embedded-opentype'),
         url("../asset/font/Product Sans Bold.ttf") format('truetype'),
         url("../asset/font/Product Sans Bold.woff") format('woff'),
         url("../asset/font/Product Sans Bold.woff2") format('woff2');
}

  
$ff-primary:'Product Sans Regular', sans-serif;
$ff-secondary:'Product Sans Bold', sans-serif;