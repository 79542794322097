@use '../abstract/colors' as *;
@use '../abstract/function' as *;
@use '../abstract/breakpoints' as *;

// for show and hide navigation
$navbar-height-lg:rem(80);
$navbar-height:rem(70);

.img-fluid{
    width:100%;
    height:auto;
}

.text-center{
    text-align:center;
}

.clr-secondary{
    color:$clr-secondary;
}