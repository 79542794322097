@use '../abstract/function' as *;
@use '../abstract/colors' as *;
@use '../abstract/breakpoints' as *;
@use '../abstract/mixins' as *;

.hero--center{
    @include flex-center;
}

.hero__content-area{
    max-width:rem(650); 
    margin-inline:auto;
}
// ===== featured home =========

.featured-home{
    background-color:$clr-sky-blue-500;
}


.about-highlight__banner{
    img{
        margin-bottom:-2rem;
    }
}

// ===== request quote =========
.request-quote__form-card{
    width:100%;
    background-color:$clr-white;
    border-radius:rem(10);
    padding:rem(24);
    box-shadow:0 3px 12px rgba($clr-black, 0.08);

    @include breakpoint-md{
        padding:rem(32);
    }

    @include breakpoint-lg{
        padding:rem(48);
    }
}

.request-quote__form{
    button[type="submit"]{
        width:100%;
        font-weight:normal;
    }
}

.why-adu__inner{
    background-color:$clr-sky-blue-500;
    margin-top:-6rem;

    @include breakpoint-md{
        margin-top:-8rem;
    }
}