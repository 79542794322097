@use '../abstract/function' as *;
@use '../abstract/colors' as *;
@use '../abstract/breakpoints' as *;
@use '../abstract/font' as *;

.footer{
    background-color:#0A122A;
}

.footer-paragraph{
    color:$clr-white;
}

.footer-logo{
    width:rem(140);
    height:auto;
    
    @include breakpoint-md{
        width:rem(160);
    }

    @include breakpoint-lg{
        width:rem(180);
    }
}

.footer-link-list{
    gap:rem(22);
    flex-wrap: wrap;

    @include breakpoint-md{
        gap:rem(32);
    }

    @include breakpoint-lg{
        gap:rem(48);
    }

    @include breakpoint-xl{
        gap:rem(58);
    }
}

.footer-link{
    color:$clr-white;
    transition:0.25s all ease-in-out;

    &:hover{
        color:$clr-secondary;
    }
}

.footer__contact-detail{
    .footer__contact-detail-link{
        display:flex;
        align-items:flex-start;
        gap:rem(12); 
        color:$clr-white;
        word-wrap: break-word;        
        &:hover{
            color:$clr-secondary;
        }

        &:hover .fa{
            color:$clr-secondary;
       
        }
    }

    .fa{
        margin-right:rem(12);
        margin-top:rem(12);
        color:$clr-white;
        font-size:rem(20);
    }
}

.social-media__list{
    gap:rem(20);

    @include breakpoint-md{
        gap:rem(32);
    }

    i{
        color:$clr-white;
        font-size:rem(26);

        &:hover{
            color:$clr-secondary;
        }
    }
}

.newsletter__form{
    gap:rem(12);
}

.newsletter-input{
    width:100%;
    padding:rem(12) rem(26);
    outline:1px solid $clr-secondary;
    font-size:rem(18); 
    font-family:$ff-primary;
}

.copyright-area{
    border-top:1px solid #7A7A7A;
}

.copy-right-text{
    color:$clr-white;
}