@use '../abstract/font' as *;
@use './colors' as *;

// ======== background cover =========
@mixin cover-background {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

// ======== flex toolkit =========//
@mixin flex-column{
    display:flex;
    flex-direction: column;
}

@mixin flex-center{
    display:flex;
    justify-content: center;
    align-items:center;
}

@mixin flex-center-horizontal{
    display:flex;
    justify-content: center;
}

@mixin flex-center-vertical{
    display:flex;
    align-items:center;
}

// ======== pseudo ============= //
@mixin pseudo-cover{
    content: '';
    position:absolute;
    top:0;
    right:0;
    bottom:0;
    left:0;
}

// ======== setting font for header and body ======= //
@mixin font-heading{
    font-family:$ff-secondary;
    color:$clr-text;
    font-weight:bold;
}

@mixin font-body{
    font-family:$ff-primary;
    font-weight:normal;
    color:$clr-text;
}