@use '../abstract/font' as *;
@use '../abstract/colors' as *;
@use '../abstract/breakpoints' as *;
@use '../abstract/function' as *;

.btn{
    display:inline-block;
    padding:rem(16) rem(24);
    font-family:$ff-secondary;
    font-size:rem(16);
    line-height:1em;
    border:none;
    outline:none;
    background-color:transparent;
    text-transform: capitalize;
    font-weight:bold;
    transition:all 0.3s ease-in-out;
    border-radius:rem(3); 

    &:hover{
        cursor:pointer;
    }

    @include breakpoint-sm{
        padding:rem(16) rem(26);
     }

    @include breakpoint-md{
        padding:rem(16) rem(32);
        font-size:rem(18);
    }

    @include breakpoint-lg{
        padding:rem(16) rem(38);
    }
}

// ===== primary button =======
.btn--primary{
    color:$clr-white;
    background-color:$clr-primary;
    outline:2px solid $clr-primary;

    &:hover{
        color:$clr-primary;
        background-color:$clr-white;
    }
}

// ===== secondary button ========
.btn--secondary{
    color:$clr-primary;
    background-color:$clr-white;
    outline:2px solid $clr-primary;

    &:hover{
        color:$clr-white;
        background-color:$clr-primary;
    }
}

// ======= submit button =========
.btn--submit{
    background-color:$clr-secondary;
    outline:2px solid $clr-secondary;
    color:$clr-white;

    &:hover{
        color:$clr-secondary;
        background-color:$clr-white;
    }
}

// ======== see more button =========
.btn--see-more{
    display:inline-flex;
    align-items:center; 
    color:$clr-text;
    font-family:$ff-secondary;

    span{
        border-bottom:2px solid $clr-text;
    }

    i{
        margin-left:8px;
        transition:all 0.3s ease-in-out;
    }

    &:hover i{
        transform:translateX(6px);
    }

    &:hover{
        color:$clr-primary;
    }

    &:hover span{
        border-color:$clr-primary;
    }
}