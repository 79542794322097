@use '../abstract/colors' as *;
@use '../abstract/breakpoints' as *;
@use '../abstract/function' as *;
@use '../abstract/mixins' as *;
@use '../abstract/variable' as *;

.nav__list{
    gap:rem(28);

    @include breakpoint-lg{
        gap:rem(32);
    }

    @include breakpoint-xl{
        gap:rem(38);
    }
}

.navbar--right {
    svg{
        margin:6px 10px 0px 0px;

        @include breakpoint-md{
            margin-top:rem(8);
        }
    }

    .navbar-toggle {
        @include flex-center-vertical;
        background: none;
        gap: rem(16);
        border: none;
        outline: none;
    }

    .navbar-toggle-block {
        width: rem(30);
        height: rem(20);
        position: relative;
    }

    .navbar-toggle-bar {
        position: absolute;
        left: 0;
        display: block;
        height: 3px;
        width: 100%;
        border-radius: rem(9999);
        background-color: $clr-black;
        transition: transform 250ms ease-in-out, opacity 150ms ease-in-out, width 150ms ease-in-out;
        transform: translate3d(0, 0, 0) rotate(0);
        transform-origin: 90% 50%;
        border-radius:2px;
    }

    .navbar-toggle-bar:nth-of-type(1) {
        top: 0;
        width: 100%;
    }

    .navbar-toggle-bar:nth-of-type(2) {
        top: 50%;
        width: 100%;
        transform: translate3d(0, -50%, 0) rotate(0);
        transform-origin: 50% 50%
    }

    .navbar-toggle-bar:nth-of-type(3) {
        bottom: 0;
        width: 100%;
    }
}

.mobile-nav{
    position: fixed;
    top: $navbar-height;
    right: 0;
    left: 0;
    bottom: 0;
    width: 60%;
    height: 100vh;
    background-color:$clr-orange-500;
    transform: translateX(-100%);
    opacity: 0;
    z-index:999;
    transition-property: all;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
    transition-duration: 300ms;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    &.is-active{
        transform: translateX(0%);
        opacity: 1;
        transition-delay: 300ms;

        .navbar-toggle-bar {
            background-color: $clr-black;
        }

        .navbar-toggle-bar {
            transform-origin: 99% 50%;
        }

        .navbar-toggle-bar:nth-of-type(1) {
            top: 0;
            width: 100%;
            transform: translate3d(0, -50%, 0) rotate(-45deg)
        }

        .navbar-toggle-bar:nth-of-type(2) {
            top: 50%;
            width: 100%;
            opacity: 0;
            transform: translate3d(0, -50%, 0) rotate(180deg);
            transform-origin: 50% 50%
        }

        .navbar-toggle-bar:nth-of-type(3) {
            bottom: 0;
            width: 100%;
            transform: translate3d(0, 80%, 0) rotate(45deg)
        }
    }
}

.nav__list--mobile {
    width: 100%;
    margin-top: calc(50px - 24px);
    display: grid;
    grid-gap: rem(8);
}

.nav__link--mobile {
    display:block;
    width:100%;
    font-size:rem(16);
    padding: rem(14) rem(24);
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
    transition-timing-function: liner;
    transition-duration: 0.35s;
    line-height: 1em;
    display: flex;
    align-items: center;
    color: $clr-black;
    
    .fa{
        margin-right:rem(16);
        font-size:rem(15);
    }

    &:hover{
        color:$clr-secondary;
      
        .fa{
            color: $clr-secondary;
        }
    }

    @include breakpoint-sm {
        padding: rem(16) rem(32);
    }
    
    @include breakpoint-md{
        padding:rem(16) 11.6%;
        font-size:rem(18);
    }
}

.nav__link.active{
    color:$clr-secondary;
}

.nav__item--mobile:hover,
.nav__item--mobile:active,
.nav__item--mobile:focus{
    background-color: $clr-secondary;

    .nav__link--mobile{
        color: $clr-black;
    }
    
    .fa{
        color: $clr-black;
    }
}

.header__wrapper--mobile{
    &.is-active{
        .navbar-toggle-bar {
            transform-origin: 99% 50%;
        }

        .navbar-toggle-bar:nth-of-type(1) {
            top: 0;
            width: 100%;
            transform: translate3d(0, -50%, 0) rotate(-45deg)
        }

        .navbar-toggle-bar:nth-of-type(2) {
            top: 50%;
            width: 100%;
            opacity: 0;
            transform: translate3d(0, -50%, 0) rotate(180deg);
            transform-origin: 50% 50%
        }

        .navbar-toggle-bar:nth-of-type(3) {
            bottom: 0;
            width: 100%;
            transform: translate3d(0, 80%, 0) rotate(45deg)
        }
    }
}

// ====== display scroll when nav menu is open ======
body.disable-scroll {
    overflow-y: hidden;

    &:after {
        transition: background-color, fill, cubic-bezier(0.39, 0.575, 0.565, 1);
        content: '';
        width: 100%;
        height: 100%;
        position: fixed;
        top: $navbar-height;
        right: 0;
        left: 0;
        bottom:0;
        z-index:3;
        background-color: rgba($clr-black, 0.55);

        @include breakpoint-lg {
            top:calc(-1 * $navbar-height-lg + rem(10));
        }
    }
}