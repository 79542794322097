@use '../abstract/colors' as *;
@use '../abstract/breakpoints' as *;
@use '../abstract/function' as *;

// ============= faq or accordion ===================
.faq {
    max-width:rem(750);
    margin:0 auto;
    margin-top:6rem; 


    @include breakpoint-md{
        margin-top:10rem;
    }
}

.accordion {
    width: 100%;
    border: none;
    outline: none;
    text-align: left;
    padding: 32px 0;
    cursor: pointer;
    transition: background-color, color 0.2s linear;
}

.accordion--primary {
    display:flex;
    justify-content: space-between;
    align-items:flex-start;
    gap:rem(12);

    &:hover {
        color: $clr-secondary;
    }
}

.accordion--secondary {
    color: $clr-white;
}

.section-heading {

    @include breakpoint-md {
        max-width: 80%;
    }
}

.faq .section-heading__text{
    color:$clr-primary;
}

.accordion--primary:after {
    font-family: FontAwesome;
    content: "\f107";
    font-weight: bold;
    font-family: "fontawesome";
    float: right;
}

.accordion--primary.is-open:after {
    content: "\f106";
}

.accordion-content {
    padding-right: 20px;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-in-out;

    p:first-child{
        padding-top: 20px;
    }

    p{
        padding-bottom: 20px;
    }
}

.accordion-content--secondary {
    p{
        color: $clr-white;
    }
}

.separator{
    border-bottom: 1px solid #BCBCBC;
}

.separator--secondary {
    border-color: #7594EC;
}