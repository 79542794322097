@use '../abstract/colors' as *;
@use '../abstract/breakpoints' as *;
@use '../abstract/function' as *;
@use '../abstract/font' as *;

.pop-up-form{
    position:relative;
    display: none;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    padding:rem(18) rem(36) rem(36) rem(36);
    max-width:rem(450);
    width:90%;
    text-align: center;
    background: #fff;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index:999999;
}

body.active{
    &:before{
        content:'';
        position:fixed;
        inset:0;
        background-color:rgba(0, 0, 0, 0.8);
        z-index:9999;
    }
}

.request-quote-form{
    margin:rem(22) rem(4) rem(4) rem(6);
}

.btn--apply{
    width:100%;
    padding:rem(14) rem(22);
    border:none;
    outline:none;
    background-color:$clr-primary;
    color:$clr-white;
    text-transform:capitalize;
    font-size:rem(16);
    transition:all 0.2s;

    &:hover{
        background-color:darken($clr-primary, 15%);
        cursor:pointer;
    }
}

.close-pop-up{
    position:absolute;
    top:-10px;
    right:-10px; 
    background-color:$clr-white;
    transition:all 0.2s ease-in-out;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 5px 12px 0px;
    border-radius:50%;
    width:rem(32);
    height:rem(32);
    padding:rem(5);
    display:flex; 
    justify-content:center;
    align-items:center;

    &:hover{
        cursor:pointer;
        background-color:darken($clr-white, 8%);
    }
}