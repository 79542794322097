@use '../abstract/function' as *;
@use '../abstract/colors' as *;
@use '../abstract/breakpoints' as *;
@use '../abstract/font' as *;
@use '../abstract/mixins' as *;

.contact-form-area{
    background-color:$clr-sky-blue-500;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.form-group{
    width:100%;
    margin-bottom:rem(20);
}

.input-control{
    display:block;
    width:100%;
    border:none;
    outline:1px solid #B7B7B7;
    padding:rem(14);
    font-size:rem(16);
    line-height:1em;
    font-family:$ff-primary;
    border-radius:rem(3);


    &:focus{
        outline:2px solid $clr-secondary;
    }

    @include breakpoint-md{
        font-size:rem(18);
    }
}

.input-control:focus::placeholder{
    opacity:0.25;
}

.textarea-notes{
    min-height:rem(128);
    resize:vertical;

    @include breakpoint-md{
        min-height:rem(156);
    }
}

.contact-form-area__icon{
    width:rem(48);
    height:rem(48);
    @include flex-center;
    background-color:$clr-white;
    border-radius:50%; 
    margin-right:rem(18);
    box-shadow: 0 8px 12px rgba($clr-black, 0.08);

    @include breakpoint-md{
        width:rem(60);
        height:rem(60);
    }
}

.main-map{
    width:100%;
    border-radius:rem(6);
}