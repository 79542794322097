@use '../abstract/function' as *;
@use '../abstract/colors' as *;
@use '../abstract/breakpoints' as *;


.hero__newsletter{
    width:100%;

    .form-group{
        gap:rem(16); 
    }

    button[type="submit"]{
        width:100%;

        @include breakpoint-sm{
            width:unset;
        }
    }

    @include breakpoint-md{
        max-width:80%; 
        margin-inline:auto;
    }
}

.blog-area{
    h3{
        color:$clr-secondary;
        margin-bottom:rem(12);
    }
}