@use '../abstract/function' as *;
@use '../abstract/colors' as *;
@use '../abstract/breakpoints' as *;

.hero{
    background-color:$clr-sky-blue;
    width:100%;
    min-height:70vh;
    overflow:hidden;

    @include breakpoint-md{
        min-height:75vh;
    }

    @include breakpoint-lg{
        min-height:85vh;
    }

    @include breakpoint-xl{
        min-height:100vh;
    }
}

.hero--home-page{
    .hero__heading-title{
        color:$clr-secondary;
    }
}

// ========= adu-simplified ==========
.adu-simplified{
    .count-digit-suffix{
        margin-left:rem(-12);
        margin-top:rem(-6);
    }
}

.section-header{
    max-width:rem(750);
    margin:0 auto;
}

.section-title{
    color:$clr-secondary; 
}

// ========== process simplified =========
.process-simplified{
    background-color:$clr-sky-blue-500;
}

.accordion{
    border-bottom:1px solid #D9D9D9;

    @include breakpoint-md{
        padding-block:rem(24);
    }
}

// ======= veteran homes section =========
.veteran-homes{
    background-color:$clr-orange-500;
    overflow:hidden; 
}

// ========= testimonial section =========
.testimonial__card{
    background-color:$clr-sky-blue;
    padding:rem(18) rem(24);
    transition:0.25s all ease-in-out;

    @include breakpoint-md{
        padding:rem(32) rem(42);
    }

    &:hover{
        box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
        cursor:pointer;
    }
}

.testimonial-person-img{
    width:rem(79);
    height:auto;
}

.testimonial__footer{
    gap:rem(18);

    @include breakpoint-md{
        gap:rem(24);
    }
}

// ========== contact us section =========
.contact-us-section{
    background-color:$clr-orange-500;
}